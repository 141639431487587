import querystring from "querystring";
import {fetch} from "@maxtropy/central-commons-ui";

const aMapKey = '675241955399d2867d23a2e65154ad9f';
const aMapPrefix = `https://restapi.amap.com/v3/place/text?`;
// 测试KEY

export interface AMapPlaceProps {
  pois: { name: string, id: string }[],

  [key: string]: any
}


export async function getAMapPlace(keywords: string): Promise<AMapPlaceProps> {
  const qsStr = querystring.encode({
    keywords,
    output: 'json',
    key: aMapKey,
    extensions: 'all',
  });
  return await fetch(`${aMapPrefix}${qsStr}`, {credentials: "omit"});
}
