import React from "react";
import s from "./cardview.module.scss";
import {ApplicationWithSubFlag} from "../../api/app";
import {classNames} from "../../lib/util";
import Empty from "./Empty";


interface CardViewProps {
  apps: ApplicationWithSubFlag[];
  onExpandApp: (appId: string) => void;
}


const CardView: React.FC<CardViewProps> = props => {
  const { apps, onExpandApp } = props;
  return (
    <div className={s.cardView}>
      {apps.length === 0 && <Empty className={s.empty}/>}
      {
        apps.map((app, i) => {
          const clzNameInvalid = !app.hasValidSub && s.invalid;
          return (
            <div key={app.appId} className={classNames(s.card, clzNameInvalid)}>
              <div className={s.content}>
                <div className={s.avatar} style={{backgroundImage: `url(${app.avatarUrl})`}}/>
                <div className={s.text}>
                  <div className={s.title}>{app.name}</div>
                  <div className={s.desc}>{app.description}</div>
                </div>
              </div>
              <div className={s.buttons}>
                <div className={s.btn} onClick={() => onExpandApp(app.appId)}>产品介绍</div>
                <div className={s.divider}/>
                <div className={classNames(s.btn, clzNameInvalid)} onClick={() => app.hasValidSub && window.location.assign(app.entryUrl)}>进入应用</div>
              </div>
            </div>
          )
        })
      }
    </div>
  );
}

export default CardView;
