import React, {CSSProperties, useCallback, useEffect, useRef, useState} from "react";
import s from "./listview.module.scss";
import {useScrollSegmentAnimation, WheelEventListener} from "./animate";
import {animated, useSpring} from "react-spring";
import {Button} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import {ApplicationWithSubFlag} from "../../api/app";
import {useCurrent, classNames} from "../../lib/util";
import Empty from "./Empty";
import {useSingletonTimeout} from "@maxtropy/central-commons-ui";

function offsetWidth(val: number): string {
  return `${-val * 25}vw`;
}

enum ActiveStage {
  NORMAL,
  PRE_ACTIVE,
  ACTIVE,
  POST_DEACTIVATE,
}

export enum FilterStage {
  STEADY = "STEADY",
  FADE_OUT = "FADE OUT",
  MOVE = "MOVE",
}

interface ListViewProps {
  apps: ApplicationWithSubFlag[];
  activeOnly?: boolean;
  initialIndex?: number;
  returnAction?: () => void;
}

function useListScrolling(listLength: number, initialIndex: number = 0) {
  const [offset, locked, onWheel, changeIndex] = useScrollSegmentAnimation({
    initialOffset: initialIndex,
    debounceMillis: 500,
    maxIndex: listLength - 1,
  });
  const setIndexScheduler = useSingletonTimeout();
  const setIndex = (nextIndex: number, nextAction?: () => void): void => {
    if (nextIndex !== offset) {
      changeIndex(nextIndex - offset);
      nextAction && !setIndexScheduler.hasSchedule() && setIndexScheduler.schedule(nextAction, 850);
    } else {
      nextAction && !setIndexScheduler.hasSchedule() && setIndexScheduler.schedule(nextAction, 0);
    }
  }
  let current = Math.round(offset);

  const aniStyle = useSpring({
    transform: `translateX(${offsetWidth(offset)})`,
    config: {
      native: true,
      tension: 250,
      friction: 30
    }
  });
  return {locked, onWheel, changeIndex, setIndex, current, aniStyle};
}

function useActivation(activeOnly: boolean | undefined) {
  const [activeStage, setActiveStage] = useState(activeOnly ? ActiveStage.PRE_ACTIVE : ActiveStage.NORMAL);
  const currCardRef = useRef<HTMLDivElement>();
  const activeStageScheduler = useSingletonTimeout();

  useEffect(() => {
    if (activeStage === ActiveStage.PRE_ACTIVE) {
      setActiveStage(ActiveStage.ACTIVE);
    } else if (activeStage === ActiveStage.POST_DEACTIVATE) {
      activeStageScheduler.schedule(() => setActiveStage(ActiveStage.NORMAL), 1600);
    }
  }, [activeStage, activeStageScheduler]);

  const enlargeCardInitStyle: CSSProperties = {
    position: "absolute",
    width: currCardRef.current?.clientWidth,
    height: currCardRef.current?.clientHeight,
    left: currCardRef.current?.offsetLeft,
    top: currCardRef.current?.offsetTop,
  }

  const cardInitStyleRef = useRef<CSSProperties>(enlargeCardInitStyle);
  if (activeStage === ActiveStage.PRE_ACTIVE) {
    cardInitStyleRef.current = enlargeCardInitStyle;
  }

  const cardRect = currCardRef.current?.getBoundingClientRect();

  const enlargeCardFinalStyle: CSSProperties = {
    position: "absolute",
    width: "calc(100vw + 3vh)",
    height: "105vh",
    left: cardRect && `calc(-3vh ${cardRect.left >= 0 ? "-" : "+"} ${Math.abs(cardRect.left)}px)`,
    top: cardRect && -cardRect.top,
  }

  const cardActivationStyle =
    activeStage === ActiveStage.PRE_ACTIVE ? enlargeCardInitStyle :
      activeStage === ActiveStage.POST_DEACTIVATE ? cardInitStyleRef.current :
        activeStage === ActiveStage.ACTIVE ? enlargeCardFinalStyle :
          undefined;

  const cardActivationClass =
    activeStage === ActiveStage.ACTIVE ? s.enlarge :
      activeStage === ActiveStage.NORMAL ? s.focus :
        activeStage === ActiveStage.PRE_ACTIVE ? s.prepareEnlarge :
          activeStage === ActiveStage.POST_DEACTIVATE ? s.postDeactivate :
            undefined;

  function toggleActivate(): void {
    if (activeStage === ActiveStage.NORMAL) {
      activeStageScheduler.cancel();
      setActiveStage(ActiveStage.PRE_ACTIVE);
    } else if (activeStage === ActiveStage.ACTIVE) {
      activeStageScheduler.cancel();
      setActiveStage(ActiveStage.POST_DEACTIVATE);
    }
  }

  return {
    activeStage,
    currCardRef,
    cardActivationStyle,
    cardActivationClass,
    toggleActivate,
  };
}

/**
 *
 * @param current
 * @param shouldFadeOut
 * @param shouldMove
 * @return [nextStage, immediate]
 */
function getNextFilterStage(current: FilterStage, shouldFadeOut: boolean, shouldMove: boolean): [FilterStage, boolean] {
  switch (current) {
    case FilterStage.STEADY:
      return [FilterStage.FADE_OUT, true];
    case FilterStage.FADE_OUT:
      return [FilterStage.MOVE, !shouldFadeOut];
    case FilterStage.MOVE:
      return [FilterStage.STEADY, !shouldMove];
  }
  return [current, true];
}

function useListArrange(apps: ApplicationWithSubFlag[], setIndex: (nextIndex: number, nextAction?: () => void) => void, current: number) {
  const [filterStage, setFilterStage] = useState(FilterStage.STEADY);
  const appsCurrent = useCurrent(apps);
  const prevAppsRef = useRef(apps);
  const prevApps = prevAppsRef.current;

  const prevAppIds = new Set(prevApps.map(app => app.appId));
  const overlapAppIds = new Set(apps.map(app => app.appId).filter(i => prevAppIds.has(i)));
  const appsChanged = apps.length !== overlapAppIds.size || prevApps.length !== overlapAppIds.size;
  const shouldFadeOut = prevApps.length !== overlapAppIds.size;
  const shouldMove = !apps.every((v, i) =>
    !overlapAppIds.has(v.appId) || prevApps[i]?.appId === v.appId);
  const shouldSkip = useCurrent([shouldFadeOut, shouldMove]);

  useEffect(() => {
    if (filterStage === FilterStage.STEADY && appsChanged) {
      setFilterStage(FilterStage.FADE_OUT);
    }
  }, [filterStage, appsChanged, setIndex, current, shouldSkip, appsCurrent]);

  const toNext = useCurrent((next: FilterStage) => {
    if (next === FilterStage.STEADY) {
      prevAppsRef.current = apps;
    } else if (next === FilterStage.MOVE) {
      setIndex(-current);
    }
    setFilterStage(next);
  });

  useEffect(() => {
    const [shouldFadeOut, shouldMove] = shouldSkip();
    const [next, immediate] = getNextFilterStage(filterStage, shouldFadeOut, shouldMove);
    if (filterStage === FilterStage.FADE_OUT || filterStage === FilterStage.MOVE) {
      if (immediate) {
        toNext()(next);
      } else {
        setTimeout(() => toNext()(next), 1000);
      }
    }
  }, [filterStage, shouldSkip, toNext]);

  const displayApps =
    filterStage === FilterStage.FADE_OUT ||
    filterStage === FilterStage.STEADY ? prevApps : apps;
  const appClass = (app: ApplicationWithSubFlag) => {
    switch (filterStage) {
      case FilterStage.FADE_OUT:
      case FilterStage.MOVE:
        if (!overlapAppIds.has(app.appId)) {
          return s.hidden;
        }
        break;
    }
    return undefined;
  };
  return {displayApps, appClass};
}

const ListView: React.FC<ListViewProps> = props => {
  const {
    apps,
    activeOnly,
    initialIndex,
    returnAction,
  } = props;

  const {
    locked,
    current,
    aniStyle,
    onWheel,
    changeIndex,
    setIndex
  } = useListScrolling(apps.length, initialIndex);

  const {
    activeStage,
    currCardRef,
    cardActivationStyle,
    cardActivationClass,
    toggleActivate,
  } = useActivation(activeOnly);

  const {displayApps, appClass} = useListArrange(apps, setIndex, current);

  const onWheelWhenInactive = useCallback<WheelEventListener>((e) => {
    if (activeStage === ActiveStage.NORMAL) {
      onWheel(e);
    }
  }, [activeStage, onWheel]);

  return (
    <>
      <div className={classNames(s.listView, activeOnly && s.activeOnly)}>
        <div className={s.slider} onWheel={onWheelWhenInactive}>
          {activeStage === ActiveStage.NORMAL && apps.length === 0 && <Empty className={s.empty}/>}
          <animated.div className={s.wrapper} style={aniStyle}>
            {
              displayApps.map((app, i) => {
                const focus = (i === current) && locked;
                return (
                  <div
                    key={app.appId}
                    data-key={app.appId}
                    style={{
                      transform: `translateX(${25 * i}vw)`,
                      zIndex: focus ? 1 : undefined
                    }}
                    className={classNames(s.tabletWrapper, appClass(app))}
                    onClick={() => setIndex(i)}
                  >
                    <div
                      ref={el => {
                        if (current === i) {
                          currCardRef.current = el || undefined;
                        }
                      }}
                      style={focus ? cardActivationStyle : undefined}
                      className={classNames(s.tablet, focus &&
                        cardActivationClass)}
                    >
                      <div className={s.appBrief}>
                        <div className={classNames(s.appLogo, !app.hasValidSub && s.invalid)}
                             style={{backgroundImage: `url(${app.avatarUrl})`}}
                        />
                        <div className={s.appDivider}/>
                        <div className={s.appName}>{app.name}</div>
                        <div className={s.appDesc}>{app.description}</div>
                        <div className={s.appButtons}>
                          {
                            app.hasValidSub &&
                            <Button type="primary" onClick={e => {
                              e.stopPropagation();
                              setIndex(i, () => window.location.assign(app.entryUrl));
                            }}>
                              进入应用
                              {focus && (
                                <>
                                  &ensp;
                                  <span className={`${s.btnArrow} ${s.arrow1}`}/>
                                  <span className={`${s.btnArrow} ${s.arrow2}`}/>
                                  <span className={`${s.btnArrow} ${s.arrow3}`}/>
                                </>
                              )}
                            </Button>
                          }
                          {app.contentUrl && <Button
                            onClick={e => {
                              e.stopPropagation();
                              if (activeStage === ActiveStage.NORMAL) {
                                setIndex(i, () => {
                                  toggleActivate();
                                });
                              } else if (activeStage === ActiveStage.ACTIVE) {
                                toggleActivate();
                                if (typeof returnAction === "function") {
                                  returnAction();
                                }
                              }
                            }}
                          >
                            {focus && (activeStage === ActiveStage.ACTIVE || activeStage === ActiveStage.PRE_ACTIVE) ? "返回" : "产品介绍"}
                          </Button>}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </animated.div>
        </div>
      </div>
      {
        activeStage === ActiveStage.NORMAL &&
        <div className={s.pager}>
          <Button disabled={apps.length === 0 || current === 0} icon={<ArrowLeftOutlined/>} onClick={() => changeIndex(-1)}/>
          <Button disabled={apps.length === 0 || current === apps.length - 1} icon={<ArrowRightOutlined/>} onClick={() => changeIndex(1)}/>
        </div>
      }
      {
        activeStage !== ActiveStage.NORMAL &&
        <div className={classNames(s.introPage, activeStage !== ActiveStage.ACTIVE && s.preparing, activeOnly && s.activeOnly)}>
          <iframe src={apps[current].contentUrl} title="应用介绍"/>
        </div>
      }
    </>
  );
}

export default ListView;
