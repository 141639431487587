import React from "react";
import {Empty} from "antd";

const PageNotFound: React.FC = props => {
  return (
    <Empty description="内容不存在" style={{paddingTop: 100}}/>
  )
}

export default PageNotFound;
