import React, {useState} from "react";
import {Button, Divider, Form, Input, message, Modal, Radio, Spin, Typography} from "antd";
import {InfoCircleFilled,ExclamationCircleOutlined} from "@ant-design/icons";
import styles from "../EditUser/index.module.scss";
import {createCustomerUser, getCurrentUser, getDefaultPassword} from "../../api/user";
import {useAsync, useSSOAuth, useSubmission, useBeforeUnload} from "@maxtropy/central-commons-ui";
import {forwardOrRefreshByQueryParam, redirectByQueryParam} from "../../lib/util";
import FullPageWrapper from "../../components/FullPageWrapper";
import FormSteps from "../../components/FormSteps";

const {useForm} = Form;
const {Text} = Typography;

const CreateUser: React.FC = () => {
  const [modified, setModified] = useState(false);
  useBeforeUnload(modified);
  const [form] = useForm();
  const current = useAsync(getCurrentUser, undefined, () => message.error("您没有权限进行当前操作"));
  const defaultPassword = useAsync(getDefaultPassword)?.password;
  const mcid = current?.customer.mcid;
  const [doSubmit, submitting] = useSubmission();
  const onSubmit = () => {
    return doSubmit(async () => {
      if (!mcid) throw new Error("页面加载未完成");
      const created = await createCustomerUser(mcid, {
        username: form.getFieldValue("username").trim(),
        name: form.getFieldValue("name").trim(),
        cellphone: form.getFieldValue("cellphone").trim(),
        disabled: form.getFieldValue("disabled")
      });
      setModified(false);
      await redirectByQueryParam(
        "redirect",
        "创建成功，正在返回应用",
        "创建成功",
        3000,
        { muid: created.muid }
      );
      form.resetFields();
    });
  };

  const onCancel = () => forwardOrRefreshByQueryParam("cancel");

  return (
    <FullPageWrapper>
      <Spin spinning={!current}>
        <div className={styles.wrapper}>
          <FormSteps/>
          <h1 className={styles.title}>{current && `${current.customerUser.username}正在${current.customer.name}下创建用户`}</h1>
          <Divider/>
          <div>
            <Form
              layout="vertical"
              form={form}
              onValuesChange={() => setModified(true)}
              onFinish={onSubmit}
              initialValues={{disabled: false}}
            >
              <div className={styles.formWrapper}>
                <Form.Item label="账号" name="username" rules={[
                  {required: true, message: "账号不能为空"},
                  {whitespace: true, message: "账号不能为空字符"},
                  // CJK： \u{4e00}-\u{9fff}  CJK扩展A： \u{3400}-\u{4dbf}  CJK扩展B： \u{20000}-\u{2a6df}
                  {pattern: /^[\dA-Za-z\u{3400}-\u{4dbf}\u{4e00}-\u{9fff}\u{20000}-\u{2a6df}]{5,20}$/u, message: "账号长度应为5~20个字符，仅支持中文、英文及数字但不能为纯数字"},
                  {pattern: /[^\d]/, message: "账号不能为纯数字"}
                ]}>
                  <Input placeholder="请输入账号" autoComplete="username"/>
                </Form.Item>
                <Form.Item label="人员姓名" name="name" rules={[
                  {required: true, message: "人员姓名不能为空"},
                  {whitespace: true, message: "人员姓名不能为空字符"},
                  {max: 50, message: "人员姓名不超过50个字符"}
                ]}>
                  <Input placeholder="请输入联系人名称" autoComplete="name"/>
                </Form.Item>
                <Form.Item label="手机号码" name="cellphone"
                           rules={[{required: true, message: "手机号码不能为空"}, {pattern: /^\d{11}$/, message: "请输入正确的手机号码"}]}>
                  <Input placeholder="请输入联系人电话" autoComplete="tel"/>
                </Form.Item>
                <Form.Item label="用户状态" name="disabled">
                  <Radio.Group>
                    <Radio value={false}>启用</Radio>
                    <Radio value={true}>禁用</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Divider/>
              <div className={styles.buttonsWrapper}>
                <Button htmlType="submit" type="primary" disabled={submitting} loading={submitting}>保存并下一步</Button>
                &emsp;
                <Button onClick={onCancel}>取消</Button>
                <span style={{display: "inline-block", width: 50}}/>
                {defaultPassword ? (
                  <Text type="secondary">
                    <InfoCircleFilled />&ensp;默认密码为{defaultPassword}，该用户登录时请重置密码
                  </Text>
                ) : null}
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    </FullPageWrapper>
  );
}

export default CreateUser;
