import React from "react";
import {AuthState, useSSOAuth} from "@maxtropy/central-commons-ui";
import {message} from "antd";


export interface AuthorizedProps {
}

const Authorized: React.FC<AuthorizedProps> = props => {
  const authState = useSSOAuth(true);
  switch (authState) {
    case AuthState.INITIAL_AUTHED:
    case AuthState.TRANSIT_AUTHED:
      return (
        <>
          {props.children}
        </>
      );
    case AuthState.TRANSIT_UNAUTHED:
      message.warn("您当前未登录，请刷新页面重新登录");
      return (
        <>
          {props.children}
        </>
      );
    default:
      return null;
  }
}

export default Authorized;
