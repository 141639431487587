import React, {useEffect} from 'react';
import {CascaderProps} from "antd/es/cascader";
import {AMapPlaceProps, getAMapPlace} from "../../api/thirdparty";
import {Select, Spin, Cascader, Upload, message, Modal, Button} from 'antd';
import {SelectProps} from "antd/es/select";
import {RcFile, UploadChangeParam, UploadProps} from "antd/es/upload";
import {UploadFile} from "antd/es/upload/interface"
import {PlusOutlined} from '@ant-design/icons'
import styles from './index.module.scss'

const {Option} = Select;
const Options = require('../../assets/area.json');

export interface AreaDataProps {
  code: string;
  name: string;
  children?: AreaDataProps[]
}

interface LocationOptionProps {
  label: string;
  value: string;
  children?: LocationOptionProps[]
}


// 不足六位自动补0
export function prefixInteger(num: string, length: number = 6) {
  if (num.length > length) return num.substring(0, length);
  return (num + Array(length).join('0')).slice(0, length);
}

// 末位补齐
export function formatCompletion(third: string): string[] {
  const first = prefixInteger(third.substring(0, 2));
  const second = prefixInteger(third.substring(0, 4));
  return [first, second, third]
}

function formatLocation(value: AreaDataProps[]): LocationOptionProps[] {
  return value.map(item => {
    if (item.children) {
      return {
        label: item.name,
        value: prefixInteger(item.code),
        children: formatLocation(item.children)
      }
    }
    return {
      label: item.name,
      value: prefixInteger(item.code),
    }
  })
}

interface LocationProps extends Omit<CascaderProps, 'options' | 'onChange' | 'value'> {
  onChange?: (value: string) => void;
  value?: string
}

export const Location: React.FC<LocationProps> = props => {

  const {onChange, value, changeOnSelect, ...restProps} = props;

  const [data, setData] = React.useState<string[]>();

  useEffect(() => {
    if (value) {
      setData(formatCompletion(value))
    }
  }, [value]);

  const onChangeFormat = (cascadeValue: string[]) => {
    const result = cascadeValue[cascadeValue.length - 1];
    setData(cascadeValue);
    if (onChange) {
      onChange(result)
    }
  };

  return (
    <Cascader
      options={formatLocation(Options)}
      onChange={onChangeFormat}
      changeOnSelect={changeOnSelect}
      value={data}
      {...restProps}
    />
  )
};

// 根据key搜索详细地址

interface OptionProps {
  id: string;
  name: string;
  value: string;
}

function formatSearch(data: AMapPlaceProps) {
  return data.pois.map((item) => ({
    id: item.id,
    name: item.name,
    value: item.name
  }))
}

let timer: number;
let currentValue: string;

function fetchAddress(value: string): Promise<AMapPlaceProps> {
  return new Promise(resolve => {
    if (timer) {
      clearTimeout(timer);
      timer = 0
    }
    currentValue = value;
    timer = window.setTimeout(() => resolve(getAMapPlace(value)), 300);
  })
}

export const SearchInput: React.FC<SelectProps<string>> = (props) => {

  const [option, setOption] = React.useState<OptionProps[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSearch = async (value: string) => {
    if (value) {
      setLoading(true);
      const option = await fetchAddress(value);
      setLoading(false);
      setOption(formatSearch(option))
    } else {
      setOption([]);
    }
  };

  return (
    <div className={styles.searchInput}>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={null}
        allowClear
        {...props}
      >
        {option.map(d => <Option value={d.value} key={d.id}>{d.name}</Option>)}
      </Select>
      <Spin spinning={loading} size="small" className={styles.spin}/>
    </div>
  );
};

function responseToFileList(value: string): UploadFile[] {
  const lists = value.split(',');
  return lists.map(item => {
    const [value, name] = item.split(';');
    return {
      name,
      url: value,
      preview: value,
      uid: value,
    } as UploadFile
  })
}

interface AttachmentUploadProps extends Omit<UploadProps, 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  fileSize?: number;
  maxLength?: number;
  accept?: string;
}

export const AttachmentUpload: React.FC<AttachmentUploadProps> = (props) => {

  const [fileList, setFileList] = React.useState<UploadFile[]>([]);

  const {listType = 'picture-card', value, onChange, accept = '.jpg,.jpeg,.png', fileSize = 1, maxLength = 1, ...restProps} = props;

  const [previewVisible, setPreviewVisible] = React.useState(false);

  const [previewImage, setPreviewImage] = React.useState<string | undefined>('');

  useEffect(() => {
    if (value && !fileList.length) {
      setFileList(responseToFileList(value))
    }
  }, [value]);

  const beforeUpload = (info: RcFile) => {
    const {name} = info;
    const suffix = name.split('.')[name.split('.').length - 1];
    const limitFileSize = info.size / 1024 / 1024 <= fileSize;
    const limitFileType = accept ? accept.includes(suffix.toLocaleLowerCase()) : true;
    if (!limitFileSize) {
      message.error(`上传文件的大小不得超过${fileSize}M`)
    }
    if (!limitFileType) {
      message.error(`请上传正确的文件格式(${accept})`);
    }
    return limitFileType && limitFileSize;
  };

  const handlePreview = (file: UploadFile) => {
    if (listType === 'text') {
      window.open(file.response)
    } else {
      if (!file.thumbUrl && !file.preview) {
        file.preview = file.response;
      }
      setPreviewImage(file.thumbUrl || file.preview);
      setPreviewVisible(true)
    }
  };

  const handleChange = (info: UploadChangeParam) => {
    const {status} = info.file;
    if (status) {
      setFileList(info.fileList)
    }
    if (status === 'done') {
      onChange && onChange(info.file.response.url);
      message.success(`${info.file.name} 上传成功`);
    } else if (status === 'error') {
      message.error(`${info.file.name} 上传失败`);
    }
  };

  const uploadButton = (
    listType === 'text' ?
      <Button>上传</Button> :
      <PlusOutlined/>
  );

  return (
    <>
      <div className={styles.attachment}>
        <Upload
          action="/api/customer/upload-logo"
          listType={listType}
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          name="logo"
          onRemove={() => onChange && onChange('')}
          beforeUpload={beforeUpload}
          {...restProps}
        >
          {fileList.length >= maxLength ? null : uploadButton}
        </Upload>
        <span className={styles.tips}>
          {`支持${accept}格式，文件小于${fileSize}MB`}
        </span>
      </div>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="preview" style={{width: '100%'}} src={previewImage}/>
      </Modal>
    </>
  )
};
