import React, {useEffect, useState} from "react";
import {ResponseCodeError, useAsync, useBeforeUnload} from "@maxtropy/central-commons-ui";
import {getCurrentUser, getDefaultPassword} from "../../api/user";
import {Button, Col, Divider, Form, Input, message, Row, Spin} from "antd";
import {InfoCircleOutlined} from '@ant-design/icons';
import {AreaDataProps, AttachmentUpload, formatCompletion, Location, prefixInteger, SearchInput} from './Forms'
import {forwardOrRefreshByQueryParam, redirectByQueryParam} from "../../lib/util";
import {
  createCustomer,
  CreateCustomerRequest,
  getCustomerDetail,
  updateCustomer,
} from "../../api/customer"
import {Store} from "rc-field-form/lib/interface";
import {useParams} from "react-router-dom"
import styles from "./index.module.scss";
import FullPageWrapper from "../../components/FullPageWrapper";
import FormSteps from "../../components/FormSteps";

const AreaJson: AreaDataProps[] = require('../../assets/area.json');

const exampleImage = require('../../assets/image/example.png');

function areaCodeToName(value: string) {
  const [province, city, area] = formatCompletion(value);
  const provinceTarget = AreaJson.find(({code}) => prefixInteger(code) === province);
  const cityTarget = provinceTarget!.children!.find(({code}) => prefixInteger(code) === city)!;
  const areaTarget = cityTarget!.children!.find(({code}) => prefixInteger(code) === area)!;
  return [provinceTarget!.name, cityTarget.name, areaTarget.name].join('')
}

function formTransform(value: string) {
  return value && value.trim()
}

//电话号码
const commonPhoneRegex = /^(?:1\d{10}|0\d{2,3}-?\d{7,8})$/;

//手机号码
const commonMobilePhoneRegex = /^\d{11}$/;

//长度5~20个字符，支持中文、英文及数字并且不能为纯数字
const commonAccountRegex = /^(?!\d+$)[\dA-Za-z\u{3400}-\u{4dbf}\u{4e00}-\u{9fff}\u{20000}-\u{2a6df}]{5,20}$/u;

//trim value ts里面使用for in不加suppressImplicitAnyIndexErrors:true会报错...
function trimValue<T>(value: T): T {
  return Object.entries(value).reduce((res, [key, value]) => {
    return Object.assign(res, {[key]: typeof value === 'string' ? value.trim() : value})
  }, {} as T)
}

interface DisplayTextProps {
  value?: string;
}

const DisplayText: React.FC<DisplayTextProps> = ({value}) => {
  return <span>{value || '--'}</span>;
};

const CreateCustomer: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const routeParams: { mcid?: string } = useParams();
  const current = useAsync(getCurrentUser);
  const password = useAsync(getDefaultPassword);

  const [modified, setModified] = useState(false);
  useBeforeUnload(modified);
  const editingMcid = routeParams.mcid;
  const isEdit = !!editingMcid;
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingMcid) {
      getCustomerDetail(editingMcid).then(data => {
        form.setFieldsValue(data);
      })
    }
  }, [form, editingMcid]);

  const onSubmit = async (valuesRaw: Store) => {
    const values: CreateCustomerRequest = valuesRaw as CreateCustomerRequest;
    setLoading(true);
    if (current) {
      try {
        if (isEdit) {
          await updateCustomer(trimValue({
            ...values,
            mcid: editingMcid!,
            provinceCityDistrict: areaCodeToName(values.adCode)
          }));
          setModified(false);
          await redirectByQueryParam("redirect", "编辑成功，正在返回应用", "编辑成功");
        } else {
          const result = await createCustomer(trimValue({
            ...values,
            parentMcid: current.customer.mcid,
            provinceCityDistrict: areaCodeToName(values.adCode)
          }));
          setModified(false);
          await redirectByQueryParam(
            "redirect",
            "创建成功，正在返回应用",
            "创建成功",
            3000,
            {mcid: result.mcid}
          );
        }
      } catch (e) {
        message.error(ResponseCodeError.getMessage(e, "保存失败"));
      }
    }
    setLoading(false);
  };

  const onCancel = () => forwardOrRefreshByQueryParam("cancel");

  const validateMessages = {
    required: '${label}不能为空',
    whitespace: '${label}不能为空字符',
    string: {
      max: '${label}不能超过${max}个字符',
    }
  };

  return (
    <FullPageWrapper>
      <Spin spinning={!current}>
        <div className={styles.wrapper}>
          <FormSteps/>
          <h1 className={styles.title}>
            {current && `${current.customerUser.username}正在${current.customer.name}下${isEdit ? '编辑' : '添加'}子客户`}
          </h1>
          <Divider/>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            validateMessages={validateMessages}
            onValuesChange={() => !modified && setModified(true)}
          >
            <div className={styles.content}>
              <Row gutter={48}>
                <Col span={12}>
                  <Form.Item
                    label="客户名称"
                    name="name"
                    rules={[
                      {required: true},
                      {whitespace: true},
                      {max: 15, transform: formTransform},
                    ]}>
                    <Input placeholder="请输入客户名称"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="客户全称"
                    name="fullName"
                    rules={[
                      {required: true},
                      {whitespace: true},
                      {max: 40, transform: formTransform},
                    ]}>
                    <Input placeholder="请输入完整名称"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="社会统一信用代码"
                    name="uscc"
                    rules={[
                      {whitespace: true},
                      {max: 18, transform: formTransform},
                    ]}>
                    <Input placeholder="请输入社会统一信用代码"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="公司电话"
                    name="phone"
                    rules={[{pattern: commonPhoneRegex, message: '请输入正确的电话'}]}>
                    <Input placeholder="请输入公司电话"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="联系人名称"
                    name="contactName"
                    rules={[
                      {required: true},
                      {whitespace: true},
                      {max: 50, transform: formTransform},
                    ]}>
                    <Input placeholder="请输入联系人名称"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="联系人手机"
                    name="contactPhone"
                    rules={[{
                      required: true, pattern: commonMobilePhoneRegex, message: '请输入正确的联系人手机'
                    }]}>
                    <Input placeholder="请输入联系人手机"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="账号"
                    name="username"
                    rules={!isEdit ? [{
                      required: true,
                      pattern: commonAccountRegex,
                      message: '长度5~20个字符，支持中文、英文及数字并且不能为纯数字'
                    }] : []}>
                    {isEdit ? <DisplayText/> :
                      <Input placeholder="请输入账号"/>}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="所属省市"
                    name="adCode"
                    rules={[{required: true}]}>
                    <Location placeholder="请选择省市区"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="详细地址"
                    name="address">
                    <SearchInput placeholder="请输入详细地址"/>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Divider/>
            <div className={styles.content}>
              <Row gutter={48}>
                <Col span={12}>
                  <Form.Item
                    label="客户logo"
                    name="logoUrl">
                    <AttachmentUpload/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div className={styles.example}>
                    <span>示例:</span>
                    <img src={exampleImage} alt="示例"/>
                  </div>
                </Col>
              </Row>
            </div>
            <Divider/>
            <div className={styles.content}>
              <Form.Item>
                <div className={styles.bottom}>
                  <div className={styles.buttons}>
                    <Button type="primary" htmlType="submit" disabled={loading}>保存并下一步</Button>
                    <Button onClick={onCancel} disabled={loading}>取 消</Button>
                  </div>
                  {password && <div className={styles.tips}>
                      <InfoCircleOutlined/>
                      <span>默认密码为{password.password}，该用户登录时请重置密码</span>
                  </div>}
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </FullPageWrapper>
  )
}

export default CreateCustomer
