import React, {useCallback, useMemo, useState} from "react";
import ListView from "./ListView";
import s from "./index.module.scss";
import Header from "../../components/Header";
import {ApplicationWithSubFlag, listApplications} from "../../api/app";
import {useAsync} from "@maxtropy/central-commons-ui";
import {Button, Input} from "antd";
import {CardIcon, ListIcon} from "./svg-icons";
import CardView from "./CardView";

enum ViewType {
  CAROUSEL,
  CARD,
}

interface ViewAttr {
  switchIcon: React.ReactNode;
  nextType: ViewType;
}

const viewAttrs: { [key in ViewType]: ViewAttr } = {
  [ViewType.CAROUSEL]: {
    switchIcon: <ListIcon/>,
    nextType: ViewType.CARD,
  },
  [ViewType.CARD]: {
    switchIcon: <CardIcon/>,
    nextType: ViewType.CAROUSEL,
  }
}

const listApplicationsOrdered = () => listApplications().then(list =>
  list.filter(app => app.subscriptionEnabled)
    .sort((a, b) => {
      if (a.hasValidSub && !b.hasValidSub) {
        return -1;
      } else if (!a.hasValidSub && b.hasValidSub) {
        return 1;
      }
      return a.ordering - b.ordering;
    }));

const Portal: React.FC = props => {
  const [viewType, setViewType] = useState(ViewType.CAROUSEL);
  const [searchText, setSearchText] = useState<string>();
  const [cardViewExpanded, setCardViewExpanded] = useState<string | null>(null);
  const appFilter = useCallback((app: ApplicationWithSubFlag) => {
    if (!searchText) return true;
    return app.name.toLowerCase().includes(searchText);
  }, [searchText]);
  const apps = useAsync(listApplicationsOrdered);
  const filteredApps = useMemo(() => apps?.filter(appFilter), [apps, appFilter]);

  return (
    <div className={s.portalWrapper}>
      <Header/>
      <main className={s.main}>
        <div className={s.top}>
          <div>
            <h2 className={s.title}>工业赋能 模式创新</h2>
            <h3 className={s.subtitle}>打造闭环赋能体系，共建工业互联网生态，落地产业应用！</h3>
          </div>
          <div style={{flex: 1}}/>
        </div>
        <div className={s.toolBar}>
          <div className={s.search}>
            <Input.Search onSearch={value => setSearchText(value)} placeholder={"请输入应用名称进行搜索"}
                          className={s.searchInput}/>
          </div>
          <div className={s.switchView}>
            <Button icon={viewAttrs[viewType].switchIcon}
                    onClick={() => setViewType(type => viewAttrs[type].nextType)}
                    className={s.switchBtn}
            />
          </div>
        </div>
        {
          filteredApps && (
            viewType === ViewType.CAROUSEL ?
              <ListView apps={filteredApps}/> :
              viewType === ViewType.CARD && !cardViewExpanded ?
                <CardView apps={filteredApps} onExpandApp={appId => setCardViewExpanded(appId)}/> :
                viewType === ViewType.CARD && cardViewExpanded ?
                  <ListView
                    apps={[filteredApps.find(app => app.appId === cardViewExpanded)].filter((x): x is ApplicationWithSubFlag => !!x)}
                    activeOnly={true}
                    returnAction={() => setCardViewExpanded(null)}
                  /> :
                  null
          )
        }
      </main>
    </div>
  );
};

export default Portal;
