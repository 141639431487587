import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import EditUser from "./pages/EditUser";
import PageForbidden from "./pages/error/PageForbidden";
import PageNotFound from "./pages/error/PageNotFound";
import CreateUser from "./pages/CreateUser";
import CreateCustomer from "./pages/CreateCustomer";
import './App.scss';
import Portal from "./pages/Portal";
import Authorized from "./components/Authorized";

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Authorized>
              <Portal/>
            </Authorized>
          </Route>
          <Route path="/create-user" exact>
            <Authorized>
              <CreateUser/>
            </Authorized>
          </Route>
          <Route path="/create-customer" exact>
            <Authorized>
              <CreateCustomer/>
            </Authorized>
          </Route>
          <Route path="/edit-customer/:mcid" exact>
            <Authorized>
              <CreateCustomer/>
            </Authorized>
          </Route>
          <Route path="/edit-user/:muid" exact>
            <Authorized>
              <EditUser/>
            </Authorized>
          </Route>
          <Route path="/error/forbidden" exact>
            <PageForbidden/>
          </Route>
          <Route path="/error/not-found" exact>
            <PageNotFound/>
          </Route>
          <Route path="*">
            <PageNotFound/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
