import {fetch} from "@maxtropy/central-commons-ui";
import {Customer} from "./customer";

export interface CreateCustomerUserRequest {
  username: string;
  name: string;
  cellphone: string;
  disabled: boolean;
}

export function createCustomerUser(mcid: string, request: CreateCustomerUserRequest): Promise<CustomerUser> {
  return fetch(`/api/customer-user/create?mcid=${mcid}`, {
    method: "POST",
    body: JSON.stringify(request)
  });
}

export interface UpdateCustomerUserRequest {
  name: string;
  cellphone: string;
  disabled: boolean;
}

export function updateCustomerUser(muid: string, request: UpdateCustomerUserRequest) {
  return fetch(`/api/customer-user/${muid}/update`, {
    method: "PUT",
    body: JSON.stringify(request)
  });
}

export interface CustomerUser {
  muid: string;
  username: string;
  name: string;
  cellphone: string;
  expired: boolean;
  disabled: boolean;
  createTime: string;
  updateTime: string;
  passwordUpdateTime: string;
}

export interface CustomerAndUser {
  customerUser: CustomerUser;
  customer: Customer;
}

export function getCustomerUser(muid: string): Promise<CustomerAndUser> {
  return fetch(`/api/customer-user?muid=${muid}`);
}

export function getCurrentUser(): Promise<CustomerAndUser> {
  return fetch("/api/customer-user/current");
}

interface PasswordResponse {
  password: string;
}

export function getDefaultPassword(): Promise<PasswordResponse> {
  return fetch("/api/default-password");
}
