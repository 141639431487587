import {useEffect, useMemo, useRef, WheelEvent} from "react";
import {Subject, merge, Observable, of} from "rxjs";
import {debounce, debounceTime, map, filter, share, delay, distinctUntilChanged} from "rxjs/operators";
import {useObservable} from "@maxtropy/central-commons-ui";

enum EventType {
  SCROLL,
  DEBOUNCE,
}

interface Event {
  id: number;
  type: EventType;
  value: number;
}

export interface WheelEventListener {
  (event: WheelEvent<unknown>): void;
}

interface NumberValueConsumer {
  (val: number): void;
}

/**
 * 用于创建id全局唯一的scroll事件，和指定id的debounce事件。
 */
class EventFactory {
  static readonly INSTANCE = new EventFactory();

  private nextId: number = 1;

  createScroll(value: number): Event {
    return {
      id: this.nextId++,
      type: EventType.SCROLL,
      value,
    };
  }

  createDebounce(id: number): Event {
    return {
      id,
      type: EventType.DEBOUNCE,
      value: 0,
    }
  }
}

/**
 * 如果一个debounce事件的id无法和紧挨着的前一个scroll事件id匹配，则将其过滤
 */
class CouplingDebounceFilter {
  private previous: Event | null = null;
  filter = (curr: Event) => {
    if (curr.type === EventType.SCROLL) {
      this.previous = curr;
    } else if (curr.type === EventType.DEBOUNCE && this.previous && curr.id !== this.previous.id) {
      return false;
    }
    return true;
  }
}

/**
 * 1. 将滚动事件和debounce事件转换为实际的index变化；
 * 2. 超范围滚动时，按对数曲线衰减滚动距离；
 */
class AccumulateDebounce {
  private index: number;
  private min: number = 0;
  private max: number = 0;

  constructor(initIndex: number = 0) {
    this.index = initIndex;
  }

  mapper = (curr: Event) => {
    if (curr.type === EventType.SCROLL) {
      this.index += curr.value;
    } else {
      // 重置到最近的index，如果超范围则重置到范围边缘
      this.index = Math.round(this.index);
    }
    if (this.index < this.min) {
      this.index = this.min;
    } else if (this.index > this.max) {
      this.index = this.max;
    }
    if (this.index === -0) {
      this.index = 0;
    }
    return this.index;
  }
  setMin = (min: number) => {
    this.min = min;
  };
  setMax = (max: number) => {
    this.max = max;
  };
  getMax(): number {
    return this.max;
  }
  getMin(): number {
    return this.min;
  }
}

/**
 * wheel事件的偏差值归一化
 */
function normalizeWheelEvent(event: WheelEvent<unknown>): number {
  return (event.deltaX + event.deltaY) / 100;
}

interface ScrollSegmentObservableResult {
  offsetObservable: Observable<number>;
  lockObservable: Observable<boolean>;
  onWheel: WheelEventListener;
  setMinIndex: NumberValueConsumer;
  setMaxIndex: NumberValueConsumer;
  changeIndex: NumberValueConsumer;
}

/**
 * 创建两个observable。
 * offsetObservable是归一化之后的偏移量流，可用于计算样式中的偏移量。
 * lockObservable是归位事件流，当滚动落入整数位时将触发true，离开整数位时触发false。可用于切换高亮样式。
 *
 * @param initIndex 初始Index
 * @param debounceMillis 滚动停止后多久归位到整数位
 * @param lockInDiff 偏离整数位多远视为已滚动到位
 * @param overscrollLogBase 超出滚动范围时的衰减对数底数
 */
export function scrollSegmentObservable(
  initIndex: number = 0,
  debounceMillis: number = 100,
  lockInDiff: number = 1,
  overscrollLogBase: number = 1000,
): ScrollSegmentObservableResult {
  const subject = new Subject<Event>();
  const accumulator = new AccumulateDebounce(initIndex);
  const offsetObservable = merge(
    subject,
    subject.pipe(
      debounceTime(debounceMillis),
      map(e => EventFactory.INSTANCE.createDebounce(e.id)),
    ),
  ).pipe(
    filter(new CouplingDebounceFilter().filter),
    map(accumulator.mapper),
    distinctUntilChanged(),
    share(),
  );
  const lockObservable = offsetObservable.pipe(
    map(val => val <= accumulator.getMin() || val >= accumulator.getMax() || Math.abs(val - Math.round(val)) < lockInDiff),
    distinctUntilChanged(),
    debounce(i => i ? of(0).pipe(delay(debounceMillis)) : of(0)),
  );

  return {
    offsetObservable,
    lockObservable,
    changeIndex: (value: number) => {
      console.log("change index", value);
      subject.next(EventFactory.INSTANCE.createScroll(value));
    },
    onWheel: (event) => {
      const diff = normalizeWheelEvent(event);
      if (Math.abs(diff) >= 0.03) {
        subject.next(EventFactory.INSTANCE.createScroll(diff));
      }
    },
    setMinIndex: accumulator.setMin,
    setMaxIndex: accumulator.setMax,
  }
}

interface UseScrollSegmentAnimationOption {
  debounceMillis: number;
  lockInDiff: number;
  overscrollLogBase: number;
  minIndex: number;
  maxIndex: number;
  initialOffset: number;
}

function defaultUseScrollSegmentAnimationOption(): UseScrollSegmentAnimationOption {
  return {
    debounceMillis: 100,
    lockInDiff: 1,
    overscrollLogBase: 1000,
    minIndex: 0,
    maxIndex: Infinity,
    initialOffset: 0,
  }
}

export function useScrollSegmentAnimation(optionParam?: Partial<UseScrollSegmentAnimationOption>): [number, boolean, WheelEventListener, NumberValueConsumer] {
  const mergedOption: UseScrollSegmentAnimationOption =
    Object.assign(defaultUseScrollSegmentAnimationOption(), optionParam);
  const optionRef = useRef(mergedOption);
  const {minIndex, maxIndex} = mergedOption;

  const {offsetObservable, lockObservable, onWheel, changeIndex, setMaxIndex, setMinIndex} =
    useMemo(() => {
      const option = optionRef.current;
      return scrollSegmentObservable(option.initialOffset, option.debounceMillis, option.lockInDiff, option.overscrollLogBase);
    }, []);

  useEffect(() => {
    setMinIndex(minIndex);
    setMaxIndex(maxIndex);
  }, [maxIndex, minIndex, setMaxIndex, setMinIndex]);

  const offset = useObservable(offsetObservable, optionRef.current.initialOffset);
  const locked = useObservable(lockObservable, true);
  return [offset, locked, onWheel, changeIndex];
}
