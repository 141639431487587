import React from "react";
import {Empty} from "antd";

const PageForbidden: React.FC = props => {
  return (
    <Empty description="您没有权限查看当前页面" style={{paddingTop: 100}}/>
  )
}

export default PageForbidden;
