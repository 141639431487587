import qs from "qs";
import {message} from "antd";
import {useCallback, useRef} from "react";

export async function redirectByQueryParam(
  param: string,
  redirectMessage: string,
  nonRedirectMessage: string,
  waitMillis: number = 3000,
  additionalParams?: {[key: string]: string}
) {
  return new Promise<void>(resolve => {
    const redirectUrl = qs.parse(window.location.search, {ignoreQueryPrefix: true})[param] as string | undefined;

    message.success(redirectUrl ? redirectMessage : nonRedirectMessage, waitMillis / 1000);
    if (redirectUrl) {
      const redir = new URL(redirectUrl);
      if (additionalParams) {
        for (let key of Object.keys(additionalParams)) {
          redir.searchParams.set(key, additionalParams[key]);
        }
      }
      setTimeout(() => {
        window.location.href = redir.toString();
        resolve();
      }, waitMillis);
    } else {
      resolve();
    }
  });
}

export function forwardOrRefreshByQueryParam(param: string) {
  const paramValue: string | undefined = qs.parse(window.location.search, {ignoreQueryPrefix: true})[param] as string | undefined;
  if (paramValue) {
    window.location.assign(paramValue);
  } else {
    window.location.reload();
  }
}

export function useCurrent<T>(value: T): () => T {
  const ref = useRef(value);
  ref.current = value;
  return useCallback(() => ref.current, []);
}

export function classNames(...items: (string|false|null|undefined)[]): string {
  return items.filter(x => !!x).join(" ");
}
