import {fetch} from "@maxtropy/central-commons-ui";

export interface Customer {
  mcid: string;
  address: string;
  phone: string;
  createTime: object;
  contactName: string;
  name: string;
  uscc: string;
  fullName: string;
  updateTime: object;
  contactPhone: string;
}

export interface Approval<T> {
  mcid: string;
  contents: T[];
}

export interface Subscription {
  appId: string;
  mcid: string;
  expireTime: string;
}

export interface GetCustomerResponse {
  adCode: string;
  address: string;
  contactName: string;
  contactPhone:string;
  createTime: string;
  disabled: boolean;
  fullName: string;
  logoUrl: string;
  mcid: string;
  name: string;
  phone: string;
  subscriptions: unknown[]
  updateTime:string;
  uscc:string;
  username: string;
}

// 新增客户
export interface CreateCustomerRequest {
  parentMcid: string;
  contactName: string;
  contactPhone: string;
  username: string;
  address?: string;
  adCode: string;
  logoUrl?: string;
  phone: string;
  name: string;
  uscc?: string;
  fullName: string;
}

export interface UpdateCustomerRequest extends CreateCustomerRequest {
  mcid: string
}

export function getCustomerDetail(mcid: string): Promise<GetCustomerResponse> {
  return fetch(`/api/customer/${mcid}`);
}

export function createCustomer(body: CreateCustomerRequest): Promise<Approval<Subscription>> {
  return fetch(`/api/customer/create?parentMcid=${body.parentMcid}`, {
    method: "POST",
    body: JSON.stringify(body)
  });
}

export function updateCustomer(body: UpdateCustomerRequest): Promise<void> {
  return fetch(`/api/customer/${body.mcid}/update`, {
    method: "POST",
    body: JSON.stringify(body)
  });
}

