import React from "react";

export const ListIcon: React.FC = () => (
  <span role="img" className="anticon">
    <svg width="1em" height="1em" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h18v2.429H0V0zm0 14.571h11.25V17H0v-2.429zm0-7.285h18v2.428H0V7.286z" fill="currentColor" fillRule="nonzero"/>
    </svg>
  </span>
);

export const CardIcon: React.FC = () => (
  <span role="img" className="anticon">
    <svg width="1em" height="1em" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.544 7.973H10.25a.886.886 0 01-.87-.87V.876c0-.469.401-.87.87-.87h6.294c.47 0 .87.401.87.87V7.17c0 .402-.4.803-.87.803zM11.121 6.3h4.62V1.68h-4.62V6.3zM7.17 7.973H.876a.886.886 0 01-.87-.87V.876c0-.469.401-.87.87-.87H7.17c.469 0 .87.401.87.87V7.17c-.067.402-.468.803-.87.803zM1.68 6.3H6.3V1.68H1.68V6.3zm14.864 11.116H10.25a.886.886 0 01-.87-.87V10.25c0-.469.401-.87.87-.87h6.294c.47 0 .87.401.87.87v6.294c0 .47-.4.87-.87.87zm-5.423-1.674h4.62v-4.62h-4.62v4.62zM7.17 17.415H.876a.886.886 0 01-.87-.87V10.25c0-.469.401-.87.87-.87H7.17c.469 0 .87.401.87.87v6.294c-.067.47-.468.87-.87.87zM1.68 15.74H6.3v-4.62H1.68v4.62z" fill="currentColor" fillRule="nonzero"/>
    </svg>
  </span>
)
