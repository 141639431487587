import React, {useCallback, useState} from "react";
import {Button, Divider, Form, Input, message, Radio, Spin} from "antd";
import styles from "./index.module.scss";
import { useParams, useHistory } from "react-router-dom";
import {getCurrentUser, getCustomerUser, updateCustomerUser} from "../../api/user";
import {useAsync, useSSOAuth, useSubmission, ResponseCodeError, useBeforeUnload} from "@maxtropy/central-commons-ui";
import {forwardOrRefreshByQueryParam, redirectByQueryParam} from "../../lib/util";
import FullPageWrapper from "../../components/FullPageWrapper";
import FormSteps from "../../components/FormSteps";

const {useForm} = Form;

const EditUser: React.FC = () => {
  const [modified, setModified] = useState(false);
  useBeforeUnload(modified);
  const history = useHistory();
  const muid: string | undefined = useParams<{muid?: string}>().muid;
  if (!muid) {
    history.push("/error/not-found");
  }
  const current = useAsync(getCurrentUser, undefined, () => message.error("您没有权限进行当前操作"));
  const getUser = useCallback(async () => {
    if (!muid) return undefined;
    return await getCustomerUser(muid);
  }, [muid]);
  const user = useAsync(getUser, undefined, err => {
    message.error(ResponseCodeError.getMessage(err, "获取用户信息失败"));
  })?.customerUser;

  const [form] = useForm();
  const [doSubmit, submitting] = useSubmission();
  const onSubmit = () => {
    return doSubmit(async () => {
      if (!muid) throw new Error("页面缺少必要参数muid");
      await updateCustomerUser(muid, {
        name: form.getFieldValue("name").trim(),
        cellphone: form.getFieldValue("cellphone").trim(),
        disabled: form.getFieldValue("disabled")
      });
      setModified(false);
      await redirectByQueryParam("redirect", "保存成功，正在返回应用", "保存成功");
    });
  };

  const onCancel = () => forwardOrRefreshByQueryParam("cancel");

  return (
    <FullPageWrapper>
      <Spin spinning={!user || !current}>
        <div className={styles.wrapper}>
          <FormSteps/>
          <h1 className={styles.title}>{current && `${current.customerUser.username}正在${current.customer.name}下编辑用户`}</h1>
          <Divider/>
          <div>
            {current && user && <Form
              layout="vertical"
              form={form}
              initialValues={{
                name: user.name,
                cellphone: user.cellphone,
                disabled: user.disabled
              }}
              onValuesChange={() => setModified(true)}
              onFinish={onSubmit}
            >
              <div className={styles.formWrapper}>
                <Form.Item required label="账号">
                  <Input disabled value={user.username}/>
                </Form.Item>
                <Form.Item label="人员姓名" name="name" rules={[
                  {required: true, message: "人员姓名不能为空"},
                  {whitespace: true, message: "人员姓名不能为空字符"},
                  {max: 50, message: "人员姓名不超过50个字符"}
                ]}>
                  <Input placeholder="请输入联系人名称" autoComplete="name"/>
                </Form.Item>
                <Form.Item label="手机号码" name="cellphone"
                           rules={[{required: true, message: "手机号码不能为空"}, {pattern: /^\d{11}$/, message: "请输入正确的手机号码"}]}>
                  <Input placeholder="请输入联系人电话" autoComplete="tel"/>
                </Form.Item>
                <Form.Item label="用户状态" name="disabled">
                  <Radio.Group>
                    <Radio value={false}>启用</Radio>
                    <Radio value={true}>禁用</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Divider/>
              <div className={styles.formWrapper}>
                <Button htmlType="submit" type="primary" disabled={submitting} loading={submitting}>保存并下一步</Button>
                &emsp;
                <Button onClick={onCancel}>取消</Button>
              </div>
            </Form>}
          </div>
        </div>
      </Spin>
    </FullPageWrapper>
  );
}

export default EditUser;
