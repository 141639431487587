import React from "react";
import s from "./index.module.scss";
import {useAsync} from "@maxtropy/central-commons-ui";
import {getCurrentUser} from "../../api/user";

const Header: React.FC = () => {
  const user = useAsync(getCurrentUser);
  return (
    <header className={s.header}>
      <h1 className={s.title}>工业互联网应用矩阵</h1>
      <div style={{flex: 1}}/>
      {user?.customerUser.name || "未登录"}&emsp;
      <a href="/server/logout" className={s.logoutBtn}>登出</a>
    </header>
  )
}

export default Header;
