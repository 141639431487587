import React, {CSSProperties} from "react";
import s from "./empty.module.scss";
import {classNames} from "../../lib/util";

interface EmptyComponentProps {
  style?: CSSProperties;
  className?: string;
}

const Empty: React.FC<EmptyComponentProps> = props => {
  return (
    <div className={classNames(s.outer, props.className)} style={props.style}>
      <div className={s.image}/>
      <div className={s.text}>暂无应用</div>
    </div>
  )
};

export default Empty;
