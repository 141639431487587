import {fetch} from "@maxtropy/central-commons-ui";

export interface ApplicationWithSubFlag {
  appId: string;
  name: string;
  simpleName: string;
  description: string;
  logoUrl: string;
  avatarUrl: string;
  entryUrl: string;
  contentUrl: string;
  ordering: number;
  subscriptionEnabled: boolean;
  createTime: string;
  updateTime: string;
  hasValidSub: boolean;
}

export function listApplications(): Promise<ApplicationWithSubFlag[]> {
  return fetch("/api/app/list");
}
